import './App.css';
import BgMountains from './assets/images/Mountain-01.png'
import Land from './assets/images/Land-01.png'
import OgreCave from './assets/images/Caves.png'
import Rocks from './assets/images/3-rock-01.png'
import Ogre from './assets/images/Ogre.png'
import OgrestownLogo from './assets/images/Ogrestown-Logo-01.png'
import Bats from './assets/images/bats.png'
import Sky from './assets/images/Sky-01.png'
import CloudsClub from './assets/images/Clouds.png'
import Fog from './assets/images/Fog-Ground-01.png'
import UpperFog from './assets/images/Upper-Fog-01.png'
import { useState} from 'react';
import MblMountain from './assets/images/mbl-mountains.png';
import ConnectButton from "./components/connect-button/ConnectButton";
function App() {
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const [mblCoord, setMblCoord] = useState({ x: 0, y: 0 });
  const getMblPercentage = (amount, total) => amount * 100/total;
  const getPercentage = (amount, total) => amount * 100/total;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const handleMouseMove = (e) => {
    if(e.screenX)
      setCoord({ x: e.screenX, y: e.screenY });
    else
      setCoord({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
  };
  const handleMblTouch = (e) => {
      setMblCoord({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
  };
  const logoTop = 20 - getPercentage(coord.y, height) * (-0.02);
  const logoLeft = 23 - getPercentage(coord.x, width) * 0.02;
  const ogreTop = 57 - getPercentage(coord.y, height) * 0.04;
  const ogreLeft = 38 - getPercentage(coord.x, width) * (-0.01);
  const ogreMblBottom = -6 - getMblPercentage(mblCoord.y, height) * (-0.04);
  const ogreMblLeft = -10 - getMblPercentage(mblCoord.x, width) * (-0.4);
  const logoMblTop = 18 - getPercentage(mblCoord.y, height) * (-0.02);
  const logoMblLeft = 15 - getPercentage(mblCoord.x, width) * 0.01;

  return (
      <>
        <ConnectButton />
        {/* {spinner ? <Loader/> :  */}
        <div id="scene" className="main" onMouseMove={handleMouseMove}  onTouchMove={handleMblTouch}>
          <div  className="mountains-layer">
            <img src={MblMountain} alt="mountains" className='mbl-view' />
            <img src={BgMountains} alt="mountains" className='web-view'/>
          </div>
          <div className="land-layer">
            <img src={Land} alt="Land" />
          </div>
          <div className="ogrecave-layer">
            <img src={OgreCave} alt="ogrecave" />
          </div>
          <div className="rocks-layer">
            <img src={Rocks} alt="rocks" />
          </div>
          <div style={{right:`${ogreLeft}%`, top:`${ogreTop}%`}} className="ogre-layer web-view">
            <img src={Ogre} alt="ogre" />
          </div>
          <div style={{left:`${ogreMblLeft}%`, bottom:`${ogreMblBottom}%`}} className="ogre-layer mbl-view">
            <img src={Ogre} alt="my image"/>
          </div>
          <div style={{right:`${logoLeft}%`, top:`${logoTop}%`}} className="ogreslogo-layer web-view">
            <img src={OgrestownLogo} alt="my image"/>
          </div>
          <div style={{left:`${logoMblLeft}%`, top:`${logoMblTop}%`}} className="ogreslogo-layer mbl-view">
            <img src={OgrestownLogo} alt="my image"/>
          </div>
          <div className="bats-layer">
            <img src={Bats} alt="bats" />
          </div>
          <div className="sky-layer">
            <img src={Sky} alt="sky" />
          </div>
          <div className="cloudclub-layer">
            <img src={CloudsClub} alt="clouds" />
          </div>
          <div className="fog-layer">
            <img src={Fog} alt="fog" />
          </div>
          <div className="upperfog-layer">
            <img src={UpperFog} alt="UpperFog" />
          </div>
        </div>
      </>
  );
}
export default App;